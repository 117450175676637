<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col">
          <h6>LISTADO DE TICKETS DE SOPORTE</h6>
        </div>
      </div>
    </div>
    <div class="card-body px-4">
      <div class="row justify-content-end">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <input type="text" class="form-control" placeholder="Buscar por nombre" v-model="pagination.search" @keyup="manualSearch">
        </div>
        <div class="col-lg-2 col-md-2 col-sm-12">
          <select name="per_page" id="per_page" v-model="pagination.limit" class="form-control" @click="reloadPaginate(1)">
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </select>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-hover table-sm border">
          <thead>
          <tr>
            <th class="text-start">
              Nombre
            </th>
            <th class="text-start">
              Asunto
            </th>
            <th class="text-start">
              Solucionado?
            </th>
            <th class="text-start">
              Fecha
            </th>
            <th class="text-start">
              Opciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in arrayTickets" :key="item.name">
            <td>{{ item.user.name }} - {{ item.user.email }}</td>
            <td>{{ item.subject }}</td>
            <td>{{ item.isSolved ? "SI" : "NO" }}</td>
            <td>{{ $filters.formatDate(item.created_at) }}</td>
            <td>
              <button class="btn btn-outline-success btn-sm mb-0" @click="view(item)">
                ver
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-between">
        <div>
          <p class="text-xs font-weight-bold mb-0">total rows: {{pagination.totalRows}}</p>
        </div>
        <div>
          <v-pagination
              v-model="pagination.page"
              :pages="pagination.pages"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="reloadPaginate"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {get} from "@/utils/httpRequest";

export default {
  data: () => ({
    arrayTickets: [],
    pagination: {
      searchTimer: null,
      page: 1,
      pages: 0,
      limit: 20,
      search: '',
      totalRows: 0
    },
  }),
  mounted() {
    this.getData()
  },
  methods: {
    reloadPaginate(page){
      this.pagination.page = page
      this.getData()
    },
    manualSearch(){
      clearTimeout(this.pagination.searchTimer);
      this.pagination.searchTimer = setTimeout(() => {
        this.reloadPaginate(1)
      }, 2000);
    },
    async getData() {
      const {data} = await get(`support?page=${this.pagination.page}&limit=${this.pagination.limit}&search=${this.pagination.search}`, null)
      this.arrayTickets = data.docs
      this.pagination.totalRows = data.count
      this.pagination.page = data.page
      this.pagination.pages = data.pages
    },
    async view(item){
      await this.$router.push({name: "support-detail", query: {id: item._id}})
    }
  }
}
</script>
<style scoped>

</style>
